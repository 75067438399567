var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-toolbar',{attrs:{"elevation":"1"}},[_c('v-toolbar-title',{staticClass:"text-h5 font-weight-regular title"},[_vm._v("Request List")]),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function () { return this$1.fetchCheckers(); }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-reload")]),_c('div',{staticClass:"reload-text"},[_vm._v("Reload")])],1)],1),_c('v-tabs',{attrs:{"fixed-tabs":"","centered":"","dark":"","background-color":"primary"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab},[_vm._v(" "+_vm._s(tab))])}),1),_c('v-row',{key:_vm.verdict,attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-2",staticStyle:{"cursor":"pointer"},attrs:{"loading":_vm.loading,"headers":_vm.headers,"disable-sort":"","disable-filtering":"","items":_vm.checkers,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalItems,"item-key":"id","id":"match-table","footer-props":_vm.footerProps},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"click:row":function (item) { return _vm.openDetailsOf(item); }},scopedSlots:_vm._u([{key:"item.checkerId",fn:function(ref){
var item = ref.item;
return [(item.checkerId)?_c('v-btn',{attrs:{"outlined":"","to":{ name: 'user.view', params: { id: item.checkerId } }}},[_vm._v(" "+_vm._s(item.checkerId)+" ")]):_c('p',{staticClass:"ma-3"},[_vm._v("-")])]}},{key:"item.makerId",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","to":{ name: 'user.view', params: { id: item.makerId } }}},[_vm._v(" "+_vm._s(item.makerId || "-")+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.verdict",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":{ APPROVED: 'success', REJECTED: 'red' }[item.verdict] || 'info'}},[_vm._v(" "+_vm._s(item.verdict || "Pending")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(_vm.verdict === 'Pending')?_c('VerdictButton',{attrs:{"small":"","id":item.id}}):_vm._e(),_c('v-btn',{staticClass:"ma-1",attrs:{"small":"","rounded":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDetailsOf(item)}}},[_vm._v(" More ")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }